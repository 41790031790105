// This ensures that the icon CSS is loaded immediately before attempting to render icons
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";

import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/800.css';
import '@fontsource/open-sans/400-italic.css';
import './src/sass/style.scss'

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false;